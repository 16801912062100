<template>
  <div>
    <div class="ml-2 text-h4">Playlists de Usários</div>
    <div v-if="playlists && playlists.length < 1">
      <no-item></no-item>
    </div>
    <div v-else>
      <playlists :items="playlists" :loggedUser="loggedUser"></playlists>
    </div>
    <create-playlist :loggedUser="loggedUser" />

    <div v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import store from '@/store'
import { getCollection } from '@/composables'
import { Playlists, CreatePlaylist } from '../components'
import { NoItem } from '@/components/layout/error'
export default {
  name: 'HomePlaylist',

  components: {
    Playlists,
    NoItem,
    CreatePlaylist
  },

  setup() {
    const loggedUser = computed(() => store.state.auth.loggedUser)
    const { error, documents: playlists } = getCollection('playlists')

    return {
      error,
      playlists,
      loggedUser
    }
  }
}
</script>

<style></style>
